.App-header {
  position: relative;
  background-color: #4d91ff;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid black;
}

.App-banner {
  color: white;
  text-align: center;
  font-size: calc(30px);
}

.Header-links {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  bottom: -1px;
}

.Header-links > * {
  border: 1px solid black;
  background-color: white;
  color: black;
  padding: 4px;
  text-decoration: none;
  font-size: 18px;
  margin: 0 5px;
  cursor: pointer;
  outline: none;
}

.Header-links > .active {
  border-bottom: none;
}

/* .Header-link.active {

} */