/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


label {
    vertical-align: top;
    display: inline-block;
    min-width: 100px;
    margin: 5px 0;
}

.button {
    background-color: #4CAF50;
    border: none;
    border-radius: 6px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
}


div.settings {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
}

    div.settings label {
        text-align: right;
        word-wrap: break-word;
    }

div.settings label:after {
            content: ":";
}

.center {
    margin-left: auto;
    margin-right: auto;
    align-content:center;
}