h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.App-page {
    /*width: 700px;*/
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    /*float: inline-start;*/
}
.Job-page {
    position: center;
}
